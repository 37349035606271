import React from "react";
// import Header from "../../../../components/header/header1/index";
import Header from "../../../../components/header/Header";
import Content from "../../../../components/main/content/DynamicContent";
// import Content from "../main/content/content";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import { graphql, Link } from "gatsby";
import "./loader.scss";
import gif_1 from "../images/1.gif";
import png_2 from "../images/2.png";
import gif_3 from "../images/3.gif";
import gif_4 from "../images/4.gif";
import png_5 from "../images/5.png";
import png_6 from "../images/6.png";
import png_7 from "../images/7.png";
import png_8 from "../images/8.png";
import png_9 from "../images/9.png";
import png_3 from "../images/3.png";

import { Helmet } from "react-helmet";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  let temp = data?.cms?.recent_story;
  let featured_post = temp.filter(checkFetured);
  function checkFetured(element) {
    return element.is_feature_post;
  }

  const sidebar = {
    png_3,
    png_3_url: "https://www.a88wptk.com/29T6R6/4FNXN4X/",
    png_8,
    png_8_url: "https://www.a88wptk.com/63CFP/49J9G9X/",
    png_9,
    png_9_url: "https://www.a88wptk.com/9W598/2ZSMPNM/",
    png_5,
    png_5_url: "https://www.a88wptk.com/PBGRT/2H6QR9N/",
    url: "https://www.a88wptk.com/?nid=1417&transaction_id=TRANSACTION_ID&amount=AMOUNT",
    gif_1,
    gif_1_url: "https://prf.hn/click/camref:1100lr3cI/pubref:{sub1}/ar:{transaction_id}/[p_id:1011l433301]",
    png_2,
    png_2_url: "https://prf.hn/click/camref:1100lqRsc/pubref:%7Bsub1%7D/ar:%7Btransaction_id%7D/[p_id:1011l433301]",
    gif_3,
    gif_3_url: "https://prf.hn/click/camref:1100lqRsc/pubref:%7Bsub1%7D/ar:%7Btransaction_id%7D/[p_id:1011l433301]",
    gif_4,
    gif_4_url: "https://prf.hn/click/camref:1101lr3rS/pubref:%7Bsub1%7D/ar:%7Btransaction_id%7D/[p_id:1011l433301]",
    png_6,
    png_6_url: "https://www.a88wptk.com/KHNZB/3QT7ZG7/",
    png_7,
    png_7_url: "https://www.a88wptk.com/KHNZB/3QT7ZG7/",
  };

  return (
    <>
      <div className="layout">
        <Helmet>
          <meta name="commission-factory-verification" content="9a2a0128730b4b2a88a55b488e9873b8" />
          <meta name="ir-site-verification-token" value="-1113842092" />
          <meta name="google-site-verification" content="y5lV9QGSNfjz1IRHuIZcYKOT6mVeiKkTeVY9zU73eAM" />
          <meta name="d103bf43fb93b7f" content="c0b9cd4d499846b447bed15f2e787549" />

          <script async src="https://www.googletagmanager.com/gtag/js?id=G-FS55TRMLV6"></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-FS55TRMLV6');`}
          </script>
          <meta name="partnerboostverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
        </Helmet>
        <div className="banner-container">
          <Link to={sidebar?.png_7_url} target="_blank">
            <img src={sidebar?.png_7} alt={"banner"} />
          </Link>
        </div>
        <Header category={data?.cms?.categories} domain={data?.cms?.domain} allPosts={allPosts} engine={options} pages={data?.cms?.pages} />
        <div className="container is-max-widescreen">
          <div className="columns m-0">
            <Content domain={data?.cms?.domain} />
            <Sidebar recent_story={featured_post} domain={data?.cms?.domain} banner={sidebar} />
          </div>
        </div>
        <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@luxuryhotels.guide"} add="Address: 2140 S Dupont Highway camden, Kent DE 19934" />
      </div>
    </>
  );
};
export default ArticlePage;

export const query = graphql`
  query listicle {
    cms {
      domain: CMS_Domain(id: "13") {
        id
        logo
        domain_url
        name
        description
        favicon
      }
      categories: CMS_CategoryByDomain(domain_id: "13") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "13") {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
